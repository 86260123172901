config = {}

config.api = '//api.beepsend.com'
config.socket= 'https://socket-aws.beepsend.com/broker'
config.connect = '//connect.beepsend.com'
config.google_analytics_id = 'UA-23709223-10'
config.livechat_enabled = false
config.livechat_license = 3634321
config.twilioSignupUrl = 'https://www.twilio.com/try-twilio?beepsend'

module.exports = config
