Model = require './model'
config = require 'config'

module.exports = class Collection extends Chaplin.Collection

  # Use the project base model per default, not Chaplin.Model
  model: Model

  # Mixin a synchronization state machine.
  _.extend @prototype, Chaplin.SyncMachine

  initialize: ->
    super
    @on 'request', @beginSync
    @on 'sync', @finishSync
    @on 'error', @unsync

  apiRoot: config.api
  urlRoot: ''

  updateSeparateModel: (model) ->
    current = @get(model.id)
    model.set current.toJSON() if current
    model

  url: () -> return @urlFor _.result(this, 'urlRoot')

  urlFor: (path) -> return @apiRoot + path

  sync: (method, model, options) ->
    if options.api_token
      options.headers = {} unless options.headers
      options.headers['Authorization'] = "Token #{options.api_token}"
    success = options.success
    options.success = (args...) =>
      return if model.disposed
      success? args...

    error = options.error
    options.error = (args...) =>
      return if model.disposed
      error? args...
    super method, model, options
