require 'lib/view-helper' # Just load the view helpers, no return value
config = require 'config'

module.exports = class View extends Chaplin.View
  # Almost all views are auto rendered to prevent screen flashes
  autoRender: true

  # Auto-save `template` option passed to any view as `@template`.
  optionNames: Chaplin.View::optionNames.concat ['template']

  # Dont bind rivets by default
  useRivets: false

  # Rivets bindings options placeholder
  rivets: null

  getTemplateData: ->
    data = super
    return data unless @sanitizeData
    utils.sanitize data

  # Precompiled templates function initializer.
  getTemplateFunction: (data) ->
    # We should return a function here so let's verify if we already have a
    # function, otherwise create one.
    # Handlebars templates will always be functions, templates/*.html will not
    return if(typeof @template is 'function') then @template else (data) => return @template


  render: ->
    super
    @_rivets?.unbind()
    if @useRivets
      @_rivets = rivets.bind @el, {
        obj: {},
        @model,
        @collection,
        view: @
        config: config
      }, @rivets or {}

  dispose: ->
    return if @disposed
    @_rivets?.unbind()
    properties = [
      'rivets', '_rivets', 'useRivets'
    ]
    delete this[prop] for prop in properties
    super
