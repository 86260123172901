User = require 'models/user'
mediator = require 'mediator'
config = require 'config'

# The application object.
module.exports = class Application extends Chaplin.Application

  initialize: ->
    @subscribeEvent 'dispatcher:dispatch', @trackVisit
    super

  # Create additional mediator properties
  # -------------------------------------
  initMediator: ->
    super

  trackVisit: (controller, params, route, options) ->
    window.ga?('send', 'pageview')
