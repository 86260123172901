View = require 'views/base/view'
config = require 'config'
utils = require 'lib/utils'

# Site view is a top-level view which is bound to body.
module.exports = class SiteView extends View
  template: require 'views/templates/site'
  useRivets: true
  container: 'body'
  events:
    'click [data-track-event]': '_trackEvent'

  id: 'public-site-container'
  regions:
    main: '#main-container'

  _trackEvent: (event) ->
    trackingData = @$(event.target).attr('data-track-event')
    return if not trackingData
    trackingData = trackingData.trim().split('|')
    utils.trackEvent.apply(null, trackingData)
