Controller = require 'controllers/base/controller'
Signup = require 'models/signup'
User = require 'models/user'
Model = require 'models/base/model'
Countries = require 'models/countries'
SignupView = require 'views/signup-view'
TwilioRedirectView = require 'views/twilio-redirect-view'
SignupVerifyView = require 'views/signup-verify-view'
CustomerFormView = require 'views/customer-form-view'
TemplateView = require 'views/base/template-view'
utils = require

module.exports = class HomeController extends Controller

  # Sign up form
  signup: (params, route, options) ->
    @model = new Signup
    @model.set('campaign', options.query.campaigncode) if options.query?.campaigncode?
    @view = new SignupView({ model: @model, region: 'main' })
    @model.initSession()

  twilioredirect: (params, route, options) ->
    @view = new TwilioRedirectView({ model: new Model(), region: 'main' })

  # Verification view from verification url sent to phone
  verify: (params) ->
    @model = new Signup()
    @model.set({ verified: false })
    @view = new SignupVerifyView {@model, region: 'main'}
    @model.verify(params.hash)
      .done () =>
        utils.trackEvent('number', 'verify success', 'SMS link')
        @model.set({verified: true})
      .fail () =>
        utils.trackEvent('number', 'verify failed', 'SMS link')
        @model.set({verification_error: true})

  # Sign up completion before first top up
  complete: (params, route, options) ->
    @model = new User api_token: params.api_token
    @countries = new Countries

    @model.set('amount': params.amount) if params.amount

    @listenTo @model, 'change:country_id', (model, country_id) =>
      country = @countries.get(country_id)
      @model.set('country_name', country.get('country_name')) if country

    @view = new CustomerFormView({
      model: @model,
      countries: @countries,
      region: 'main'
    })
    @model.fetch({ api_token: params.api_token }).fail (xhr) =>
      if(xhr.status is 401)
        @redirectTo('home#notFound')
      else
        @redirectTo('home#serviceUnavailable')

    @countries.fetch({ api_token: params.api_token }).fail (xhr) =>
      if(xhr.status is 401)
        @redirectTo('home#notFound')
      else
        @redirectTo('home#serviceUnavailable')


  serviceUnavailable: ->
    @view = new TemplateView
      region: 'main'
      template: require('views/templates/service-unavailable')


  notFound: ->
    @view = new TemplateView
      region: 'main'
      template: require('views/templates/not-found')
