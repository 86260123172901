# Application-specific utilities
# ------------------------------

# Delegate to Chaplin’s utils module.
utils = Chaplin.utils.beget Chaplin.utils

PNF = libphonenumber.PhoneNumberFormat
PhoneNumber = libphonenumber.PhoneNumberUtil.getInstance()

_.extend utils,
  openLiveChat: () ->
    if LC_API? and LC_API.agents_are_available() and not LC_API.visitor_engaged()
      LC_API.open_chat_window()

  trackEvent: (category, action, label, value) ->
    window.ga?('send', 'event', category, action, label, value)

  formatPhone: (value, country) ->
    return value if not value?
    return value if value.length < 7
    return value unless value.match(/^\+?[()\d- ]+$/)
    formattedNumber = if value[0] isnt '+' then '+' + value else value
    country = if country then country.toUpperCase() else 'ZZ'
    try
      phone = PhoneNumber.parse(formattedNumber, country)
      if PhoneNumber.isValidNumber(phone)
        return PhoneNumber.format(phone, PNF.INTERNATIONAL)
      else
        return value
    catch error
      return value


  sanitizePhone: (value, country) ->
    return value if not value?
    return value if value.length < 7
    return value unless value.match(/^\+?[()\d- ]+$/)

    formattedNumber = if value[0] isnt '+' then '+' + value else value
    country = if country then country.toUpperCase() else 'ZZ'
    try
      phone = PhoneNumber.parse(formattedNumber, country)
      if PhoneNumber.isValidNumber(phone)
        return PhoneNumber.format(phone, PNF.E164).substring(1)
      else
        return value
    catch error
      return value

  ###
    Utlity function to validate a phone number
    Returns true or false depending on if it's valid phone
    number or not. Some examples below
    if value = 0                       -> return false
    if value +46#702137998             -> return false
    if value +46 702 13 79 98          -> return true
    if value 46702137998               -> return true
    if value is 72000                  -> return true
  ###
  validatePhone: (value, country) ->
    return false if not value? or value.length >= 20 or value.length < 3

    return true if /^\d{3,7}$/.test(value)
    return false unless value.match(/^\+?[()\d- ]+$/)

    value = '+' + value if value[0] isnt '+'
    country = if country then country.toUpperCase() else 'ZZ'
    phone = PhoneNumber.parse(value, country)

    return if phone and PhoneNumber.isValidNumber(phone) then true else false

  dlrMap:
    "DELIVRD": "delivered",
    "UNDELIV": "undelivered",
    "FAILED": "undelivered",
    "DELETED": "undelivered",
    "REJECTD": "rejected",
    "EXPIRED": "expired",
    "UNKNOWN": "unknown",
    "": "no_dlr"

  passwordStrength: (password = '') ->
    passCheckRes = utils.checkPassword(password)
    level = 0
    return level if not passCheckRes.valid
    level += 1 if passCheckRes.valid
    level += Math.min(passCheckRes.conditions, 4) - 1
    return level

  getSMSData: (text) ->
    data = {}
    data.GSM7 = @isGSM7(text)
    data.multipart = @isMultipart(text, data.GSM7)

    data.msgMaxLength = if data.GSM7 then 160 else 70
    data.msgMaxLength = (if data.GSM7 then 153 else 66) if data.multipart

    data.multipartCount = Math.ceil(text.length / data.msgMaxLength)
    data.charsToNextFull = (data.multipartCount * data.msgMaxLength) - text.length
    data.currentSMSLength = text.length % data.msgMaxLength
    return data

  isGSM7: (message) ->
    #Mapping of ALL permitted GSM7 characters found in Unicode/UTF8 character
    #set. NB. The values used are those in the Unicode/UTF8 hex values table.
    str_map   = /^[\u0040\u00A3\u0024\u00A5\u00E8\u00E9\u00F9\u00EC\u00F2\u00E7\u00C7\u000A\u00D8\u00F8\u000D\u00C5\u00E5\u0394\u005F\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039E\u00A0\u000C\u005E\u007B\u007D\u005C\u005B\u007E\u005D\u007C\u20AC\u00C6\u00E6\u00DF\u00C9\u0020\u0021\u0022\u0023\u00A4\u0025\u0026\u0027\u0028\u0029\u002A\u002B\u002C\u002D\u002E\u002F\u0030\u0031\u0032\u0033\u0034\u0035\u0036\u0037\u0038\u0039\u003A\u003B\u003C\u003D\u003E\u003F\u00A1\u0041\u0042\u0043\u0044\u0045\u0046\u0047\u0048\u0049\u004A\u004B\u004C\u004D\u004E\u004F\u0050\u0051\u0052\u0053\u0054\u0055\u0056\u0057\u0058\u0059\u005A\u00C4\u00D6\u00D1\u00DC\u00A7\u00BF\u0061\u0062\u0063\u0064\u0065\u0066\u0067\u0068\u0069\u006A\u006B\u006C\u006D\u006E\u006F\u0070\u0071\u0072\u0073\u0074\u0075\u0076\u0077\u0078\u0079\u007A\u00E4\u00F6\u00F1\u00FC\u00E0]*$/i;

    str_map.test(message) is true

  getNotGSM7Characters: (message) ->
    #Mapping of ALL permitted GSM7 characters found in Unicode/UTF8 character
    #set. NB. The values used are those in the Unicode/UTF8 hex values table.
    characters = []
    return characters if typeof message isnt 'string'
    str_map   = /[\u0040\u00A3\u0024\u00A5\u00E8\u00E9\u00F9\u00EC\u00F2\u00E7\u00C7\u000A\u00D8\u00F8\u000D\u00C5\u00E5\u0394\u005F\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039E\u00A0\u000C\u005E\u007B\u007D\u005C\u005B\u007E\u005D\u007C\u20AC\u00C6\u00E6\u00DF\u00C9\u0020\u0021\u0022\u0023\u00A4\u0025\u0026\u0027\u0028\u0029\u002A\u002B\u002C\u002D\u002E\u002F\u0030\u0031\u0032\u0033\u0034\u0035\u0036\u0037\u0038\u0039\u003A\u003B\u003C\u003D\u003E\u003F\u00A1\u0041\u0042\u0043\u0044\u0045\u0046\u0047\u0048\u0049\u004A\u004B\u004C\u004D\u004E\u004F\u0050\u0051\u0052\u0053\u0054\u0055\u0056\u0057\u0058\u0059\u005A\u00C4\u00D6\u00D1\u00DC\u00A7\u00BF\u0061\u0062\u0063\u0064\u0065\u0066\u0067\u0068\u0069\u006A\u006B\u006C\u006D\u006E\u006F\u0070\u0071\u0072\u0073\u0074\u0075\u0076\u0077\u0078\u0079\u007A\u00E4\u00F6\u00F1\u00FC\u00E0]/i;

    for char in message
      characters.push(char) if not str_map.test(char)

    return characters

  isMultipart: (message, isGSM7) ->
    (isGSM7 and message.length > 160) or (not isGSM7 and message.length > 70)

  formatISO8601: (value) ->
    return '' unless value
    value = new Date(value).getTime() / 1000
    value = moment.unix(value)
    if value.isValid()
      value.format('L LT')
    else
      ''

  checkPassword: (string) ->
    result = {
      length: false,
      hasUppercase: false,
      hasLowercase: false,
      hasNumber: false,
      hasSpecialChar: false,
      conditions: 0,
      valid: false
    }
    wordCharArr = string.replace(/\W|\d/g, '').split('')
    result.lengthOK = string.length >= 16
    result.hasUppercase = wordCharArr.some((char) -> char is char.toUpperCase())
    result.hasLowercase = wordCharArr.some((char) -> char is char.toLowerCase())
    result.hasNumber = /\d/.test(string)
    result.hasSpecialChar = /\W/.test(string)
    conditions = ['hasUppercase', 'hasLowercase', 'hasNumber', 'hasSpecialChar']
    conditions.forEach((key) -> result.conditions += 1 if result[key])
    result.valid = result.lengthOK
    return result

# Prevent creating new properties and stuff.
Object.seal? utils

module.exports = utils
