View = require 'views/base/view'
config = require 'config'

module.exports = class SignupVerifyView extends View
  useRivets: true
  template: require 'views/templates/twilio-redirect-view'

  initialize: () ->
    @model.set('countdown', 10)
    @redirectUrl = config.twilioSignupUrl
    @countdownInterval = setInterval(() =>
      oldCountdown = @model.get('countdown')
      if(oldCountdown == 0)
        document.location = config.twilioSignupUrl
      else
        @model.set('countdown', oldCountdown - 1)
    , 1000)

  render: () -> super

  dispose: () ->
    return if @disposed
    clearInterval(@countdownInterval)
