Model = require '/models/base/model'
mediator = require 'mediator'
utils = require 'lib/utils'

module.exports = class User extends Model

  urlRoot: '/2/users/me'

  defaults:
    country: null

  initialize: () ->
    super

  convert: =>
    attrs =
      name: @get('company_name') or @get('name')
      address: @get('address')
      post_box: @get('post_box')
      city: @get('city')
      country_id: @get('country_id')
      country_name: @get('country_name')
    vat = @get('vat')
    attrs.vat = vat if vat
    syncData =
      url: @urlFor '/internal/users/register/convert/'
      attrs: attrs
      api_token: @get('api_token')
    @sync('create', this, syncData)
      .done () =>
        utils.trackEvent('user', 'convertToCustomer', @id)
