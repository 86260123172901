Collection = require 'models/base/collection'
Country = require './country'

module.exports = class Countries extends Collection
  model: Country

  urlRoot: '/internal/countries/signup/'

  comparator: 'country_name'

  getCountryByName: (name) ->
    name = name.toLowerCase()
    for country in @models
      if country.get('country_name').toLowerCase() is name
        return {country_id: country.id, country_name: country_name}
