Application = require 'application'
routes = require 'routes'
config = require 'config'


##== Moment
##
#### Configure date and time formats
moment.locale 'en',
  longDateFormat:
    LT: "HH:mm" # If this is to change. Check places in the code where LT:ss is used so that they won't break.
    L: "YYYY-MM-DD"
    LL: "D MMMM YYYY"
    LLL: "D MMMM YYYY LT"
    LLLL: "dddd D MMMM YYYY LT"
  calendar:
    lastDay: '[Yesterday at] LT'
    sameDay: '[Today at] LT'
    nextDay: '[Tomorrow at] LT'
    lastWeek: '[Last] dddd [at] LT'
    nextWeek: 'dddd [at] LT'
    sameElse: 'L'
  week:
    dow: 1, # Monday is the first day of the week.
    doy: 4  #The week that contains Jan 4th is the first week of the year.

##== Application
##
#### Start up the machinery
new Application {
  title: 'Beepsend',
  controllerSuffix: '-controller',
  routes
}
