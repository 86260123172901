View = require 'views/base/view'
Model = require 'models/base/model'
config = require 'config'

module.exports = class CustomerFormView extends View
  template: require './templates/customer-form'

  useRivets: true
  optionNames: View::optionNames.concat ['countries']

  listen:
    'change model': 'validateForm'

  events:
    'submit form': 'submit'
    'click [data-action="validate_vat"]': 'validateVAT'
    'keydown #country': 'preventBackspace'

  initialize: () ->
    @viewData =
      submitEvent: false

  submit: (event) ->
    event.preventDefault()
    @autoValidate = true
    return if not @validateForm()
    @viewData.submitEvent = true
    @model.convert()
      .done () =>
        @viewData.submitEvent = false
        next = encodeURIComponent("/wallets/?amount=#{@model.get('amount')}")
        window.location = "#{config.connect}/auth/#{@model.get('api_token')}?next=#{next}"
      .fail (jqXHR) =>
        @viewData.submitEvent = false
        @model.set errors: jqXHR.responseJSON.errors

  preventBackspace: (event) -> event.preventDefault() if (event.which or event.keyCode) is 8

  validateForm: () ->
    return if not @autoValidate
    isValid = true
    ['address', 'post_box', 'city', 'country_id'].forEach (attr) =>
      attrValid = if @model.get(attr) then true else false
      isValid = false if not attrValid
      @[attr + '_error'] = not attrValid

    return isValid

  validateVAT: (data) =>
    cid = @model.get('country_id')
    @VATerrors = []
    @VATerrors.push('You need to select a country first') if not cid
    return if not cid
    @validatingVAT = true
    @VATValid = false
    vatModel = new Model()
    vatModel.urlRoot = "/internal/utils/vat-check/#{cid}/#{@model.get('vat')}"
    serviceErrorMessage = 'We can\'t validate VAT numbers at this moment. Please contact your account manager'
    vatModel.fetch()
      .done (data) =>
        if data is false
          @VATerrors.push('Invalid VAT, please verify it and try again')
          @VATValid = false
        else if data is null
          @VATerrors.push(serviceErrorMessage)
          @VATValid = false
        else
          @VATValid = true
      .fail () =>
        @VATerrors.push(serviceErrorMessage)
      .always () =>
        @validatingVAT = false

  render: ->
    super

  dispose: ->
    return if @disposed
    super
