View = require 'views/base/view'
config = require 'config'
utils = require 'lib/utils'

module.exports = class SignupView extends View
  template: require 'views/templates/signup'

  autoRender: true
  useRivets: true

  listen:
    'change:phone model': 'setCountry'
    'change:phone model': 'validateNumber'
    'change:password model': 'validatePassword'

  events:
    'click [data-action="send_number"]': 'registerNumber'
    'click [data-action="send_verification"]': 'verifyNumber'
    'click [data-action="complete_signup"]': 'completeSignup'
    'click [data-action="restart_signup"]': 'restartSignup'
    'submit #number-form': 'registerNumber'
    'click [data-action="togglePasswordHelp"]': 'togglePasswordHelp'
    'keyup #verification-form .verification-input': 'onCodeInput'
    'paste #verification-form .verification-input': 'onVerificationPaste'
    'click #verification-form .verification-input': 'onClickInput'

  termsHref: "#{config.connect}/terms"

  initialize: () ->
    @numberValid = true
    @errors = []
    @registerErrors = []
    super

  togglePasswordHelp: (e) ->
    elm = @$('.password-rules')
    target = $(e.target)
    elm.toggleClass('show')
    target.html(if elm.hasClass('show') then 'less...' else 'more...')

  passwordCheckResult: {}
  validatePassword: () ->
    password = @model.get('password')
    _.assign(@passwordCheckResult, utils.checkPassword(password or ''))

  setCountry: (event) ->
    @model.set country: @$('#phone').intlTelInput('getSelectedCountryData').iso2

  restartSignup: (event) -> @model.restartSignup()

  # validatenumber runs when trying to send, then continuously until it's correct. it's magic, sorry
  validateNumber: () =>
    return true if not @validateNumberActive
    @numberValid = utils.validatePhone(@model.get('phone'), @model.get('country'))

  registerNumber: (event) =>
    event.preventDefault()
    @validateNumberActive = true
    @validateNumber()
    if @numberValid
      @validateNumberActive = false
      @model.register()
        .done () =>
          @registerErrors = []
        .fail (data) =>
          @model.set('state', 0)
          if data.status is 409
            number  = utils.formatPhone(@model.get('phone'))
            @registerErrors = ["The number #{number} is already tied to an account"]
          else
            @registerErrors = ['The service is temporarily unavailable, please try again later']

  verifyNumber: (event) ->
    @model.verify(@model.get('verification_code'))
      .done () =>
        utils.trackEvent('number', 'verify success', 'Manual')
        @model.fetch()
      .fail () =>
        utils.trackEvent('number', 'verify failed', 'Manual')

  validateFinalForm: () =>
    @errors = []
    email = @model.get('email')
    name = @model.get('name')
    password = @model.get('password')
    terms = @model.get('terms')

    emailOK = /^.+\@.+\..+$/.test(email)
    passwordCheckResult = utils.checkPassword(password or '')
    passwordOK = passwordCheckResult.valid
    nameOK = name?.length > 0

    if not email
      @errors.push('Email is required')
    else if not emailOK
      @errors.push('The email is not valid')

    @errors.push('Name is required') if not nameOK
    @errors.push('You must choose valid password') if not passwordOK
    @errors.push('You must accept the terms and conditions') if not terms

    return @errors.length is 0


  completeSignup: () =>
    return if not @validateFinalForm()
    onSuccess = (data) =>
      @connectURL = "#{config.connect}/auth/#{data.api_token}"
      @model.set('state', 10)
    @model.activate()
      .done(onSuccess)
      .fail (xhr) =>
        if(xhr.status is 403 and xhr.responseJSON?.errors?)
          @errors = xhr.responseJSON.errors
        else
          @model.set('state', 9)


  render: ->
    super
    @renderCountryPhoneInput()

  renderCountryPhoneInput: ->
    $phoneInput = @$('#phone')
    onFail = (coverageFail, detectedCountryFail) =>
      utils.trackEvent('dropdown', 'loading failure', 'countries')

    onSuccess = (coverage, detectedCountry) =>
      coverage = coverage[0]
      detectedCountry = detectedCountry[0]
      countries = coverage.map (country) => return country.country_code.toLowerCase()
      defaultCountry = @model.get('country')
      detectedCountry = detectedCountry.address.country_code.toLowerCase()
      selectedCountry = countries[0]
      if countries.indexOf(detectedCountry) isnt -1
        selectedCountry = detectedCountry
      else if countries.indexOf(defaultCountry) isnt -1
        selectedCountry = defaultCountry

      @model.set('state', 16) if countries.indexOf(selectedCountry) is -1

      $phoneInput.intlTelInput({
        preferredCountries: [],
        defaultCountry: selectedCountry
        onlyCountries: countries
      })

      $phoneInput.focus()
      @model.set(country: selectedCountry)

    $.when(
      @model.sync('read', @model, url: @model.urlFor('/internal/pricelists/default_coverage/')),
      $.getJSON("//api.wipmania.com/jsonp?callback=?")
    ).done(onSuccess).fail(onFail)



  dispose: ->
    return if @disposed
    @$('#phone').intlTelInput('destroy')
    @socket?.close()
    delete @socket
    super

  # When key is released check which key is pressed and process it
  onCodeInput: (event) =>
    input = @$(event.target)
    keyCode = event.keyCode || event.which
    if keyCode is 8 and input.val().length is 0
      nextInput = input.prev('.verification-input')
    else if /\w/.test(input.val())
      nextInput = input.next('.verification-input')
    if nextInput and nextInput.length isnt 0
      @selectInput(nextInput)
    @extractVerificationCode()

  onVerificationPaste: (event) ->
    e = event.originalEvent
    pasted = e.clipboardData?.getData?('text/plain')
    windowsPasted = window.clipboardData?.getData?('Text')
    return if not (pasted or windowsPasted)?.split? # we could not extract pasted string
    pastedData = (pasted or windowsPasted).split('')
    currentInput = @$(event.target)
    while pastedData.length and currentInput.length
      currentInput.val(pastedData.shift())
      currentInput = currentInput.next('.verification-input')
    @extractVerificationCode()

  onClickInput: () => @selectInput($(event.target))

  extractVerificationCode: () ->
    extractValue = (input) -> return input.value
    verificationCode = @$('.verification-input').toArray().map(extractValue).join('')
    @model.set('verification_code', if verificationCode.length is 8 then verificationCode else null)

  selectInput: (input) ->
    input.focus()
    input.get(0).setSelectionRange(0, 1) if input.val().length > 0
