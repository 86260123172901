Model = require '/models/base/model'
utils = require 'lib/utils'

module.exports = class Signup extends Model

  urlRoot: '/internal/users/register/'

  idAttribute: 'phone'

  tooLongWaitingTime: 15 # in seconds
  pollInterval: 1500

  defaults:
    state: 0
    country: 'se'
    smsMaybeLost: false

  initialize: ->
    super
    @poll()
    @listenTo @, 'change:country', () => localStorage.setItem('bs-signup-country', @get('country'))

    @listenTo @, 'change:state', @poll
    @listenTo @, 'change:state', () =>
      return if @getPhase() isnt 'sending_verification'
      clearTimeout(@sendingTimeout)
      @sendingTimeout = setTimeout () =>
        return if @disposed
        @set('smsMaybeLost', true)
      , @tooLongWaitingTime * 1000

  clearLocalStorage: () ->
    localStorage.removeItem('bs-signup')
    localStorage.removeItem('bs-signup-country')

  initSession: ->
    phone = localStorage.getItem('bs-signup')
    country = localStorage.getItem('bs-signup-country')
    @set('country', country)
    if not phone
      return (new $.Deferred()).reject()
    else
      @set('phone', phone)
      @fetch()

  restartSignup: () ->
    @clear()
    reuseData = {
      phone: localStorage.getItem('bs-signup'),
      country: localStorage.getItem('bs-signup-country')
    }
    @set(_.assign(reuseData, @defaults))

    localStorage.removeItem('bs-signup')


  destroy: ->
    @clear()
    @set('state', 0)
    localStorage.removeItem('bs-signup')

  # this is just translating signup states to human readable states
  # noteworthy: 0, 2, 4, 6, 8, 12 & 14 are defined in the backend
  translateState: () ->
    stateName = ''
    switch @get('state')
      when 0 then stateName = 'start'
      when 1 then stateName = 'verification_start'
      when 2 then stateName = 'verification_sending'
      when 4 then stateName = 'verification_sent'
      when 6 then stateName = 'verification_received'
      when 8 then stateName = 'phone_verified'
      when 9 then stateName = 'submit_failed'
      when 10 then stateName = 'signup_complete'
      # PHONE_FAILED means we couldn't deliver the message
      when 12 then stateName = 'phone_failed'
      # PHONE_DISABLED means the destination wasn't in the signup pricelist
      # (we should never get this, since we validate the numbers against default coverage=signup pricelist)
      when 14 then stateName = 'phone_disabled'
      when 16 then stateName = 'no_coverage'
    return stateName

  # this is for determining which phase you are in during the signup
  getPhase: () ->
    phase = ''
    switch @get('state')
      when 0 then phase = 'start'
      when 1 then phase = 'sending_verification'
      when 2 then phase = 'sending_verification'
      when 4 then phase = 'sending_verification'
      when 6 then phase = 'sending_verification'
      when 8 then phase = 'phone_verified'
      when 9 then phase = 'phone_verified'
      when 10 then phase = 'signup_complete'
      when 12 then phase = 'phone_failed'
      when 14 then phase = 'phone_disabled'
      when 16 then phase = 'start'
    return phase

  # never call this with arguments. sorry, it's magic
  poll: (start = true) =>
    state = @get('state')
    if @disposed or (state >= 8 or state <= 1) or not @get('phone')
      # autostop loop and reset @_poll so we can start it again if necessary
      @_poll = null
      return
    return if start and @_poll # only allow recursive fetches if already running
    @fetch().always () => @_poll = setTimeout(@poll.bind(@, false), @pollInterval)

  activate: ->
    attrs = {}
    ['name', 'email', 'password', 'campaign', 'terms'].forEach (key) => attrs[key] = @get(key) || ''
    data =
      url: @urlFor("/internal/users/register/activate/#{@id}")
      attrs: attrs
    return if @get('activating')
    @set('activating', true)
    @sync('update', this, data)
      .done () =>
        utils.trackEvent('user', 'signed up', @get('email') + ' ' + @id )
      .always () =>
        @set('activating', false)

  register: ->
    @set('state', 1)
    @set('registering', true)
    number = utils.sanitizePhone(@get('phone'))
    @sync('create', this, { url: @urlFor(@urlRoot), attrs: { phone: number } })
      .done (data) =>
        @set(data)
        utils.trackEvent('number', 'registered', number)
      .always () =>
        @set('registering', false)


  verify: (hash) ->
    verifyURL = @urlFor("/internal/users/register/verify/#{hash}")
    @set('verifying', true)

    request = @sync('create', @, {url: verifyURL})
    request.done (data) =>
      @set(data)
      @set('verification_error', false)
      @set('verified', true)
    request.fail () => @set('verification_error', true)
    request.always () =>
      @set('verifying', false)
    return request
