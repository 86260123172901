config = require 'config'

# Base model.
module.exports = class Model extends Chaplin.Model
  # Mixin a synchronization state machine.
  _.extend @prototype, Chaplin.SyncMachine

  apiRoot: config.api

  initialize: ->
    super
    @on 'request', @beginSync
    @on 'sync', @finishSync
    @on 'error', @unsync

  url: ->
    base = super
    return if base.indexOf(@apiRoot) is -1 then @urlFor base else base

  urlFor: (path) ->
    return @apiRoot + path

  sync: (method, model, options) ->
    if options.api_token
      options.headers = {} unless options.headers
      options.headers['Authorization'] = "Token #{options.api_token}"

    success = options.success
    options.success = (args...) =>
      return if model.disposed
      success? args...

    error = options.error
    options.error = (args...) =>
      return if model.disposed
      error? args...

    super method, model, options
